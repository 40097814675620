class _cookieHandler {
  isValidCookie(cookieName) {
    var cookieValue = this.getCookieValue(cookieName);
    return (cookieValue !== '');
  };

  setCookie(cookieName, cookieValue, nDays = 1) {
    let d = new Date();
    nDays === 1 ? d.setTime(new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 99).getTime())
      : d.setTime(d.getTime() + (nDays * 24 * 60 * 60 * 1000));
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cookieName + '=' + cookieValue +
      ';' + expires + ';path=/';
  };

  getCookieValue(cookieName) {
    let name = cookieName + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  deleteCookie(cookieName) {
    this.setCookie(cookieName, '', -730);
  };
};
const CookieHandler = new _cookieHandler();

export {CookieHandler}