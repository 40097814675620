import {
  ADD_TO_CART,
  CHANGE_QTY,
  CHANGE_SHIPPING,
  CLEAR_CART,
  HIDE_ADD_TO_CART_MODAL,
  OPEN_ADD_TO_CART_MODAL,
  REFRESH_STORE,
  REMOVE_FROM_CART
} from "../../constants/action-types";
import {findIndex} from "../../utils";
import {persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage';

const initialState = {
  cart: [],
  shipping: "free",
  showModal: false
}

function cartReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_CART:
      const productId = action.product.productId;

      if (findIndex(state.cart, product => product.productId === productId) !== -1) {
        const cart = state.cart.reduce((cartAcc, product) => {
          if (product.productId === productId) {
            cartAcc.push({
              ...product,
              qty: parseInt(product.qty) + parseInt(action.qty),
              sum: (product.discount ? product.salePrice : product.priceValue) * (parseInt(product.qty) + parseInt(action.qty))
            }) // Increment qty
          } else {
            cartAcc.push(product)
          }
          return cartAcc
        }, [])

        return {...state, cart}
      }

      return {
        ...state,
        cart: [
          ...state.cart,
          {
            ...action.product,
            qty: action.qty,
            sum: (action.product.discount ? action.product.salePrice : action.product.priceValue) * action.qty
          }
        ]
      }

    case REMOVE_FROM_CART:
      return {
        ...state,
        cart: state.cart.filter(item => item.productId !== action.productId)
      };

    case CHANGE_QTY:
      const cart = state.cart.reduce((cartAcc, product) => {
        if (product.productId === action.productId) {
          // cartAcc.push( { ...product, qty: action.qty, sum: ( product.discount ? product.salePrice : product.price ) * action.qty } ) // Increment qty
          cartAcc.push({...product, qty: action.qty, sum: (product.priceValue) * action.qty}) // Increment qty
        } else {
          cartAcc.push(product)
        }
        return cartAcc;
      }, [])

      return {...state, cart};

    case REFRESH_STORE:
      return {...state, cart: [], shipping: "free"};

    case CHANGE_SHIPPING:
      return {...state, shipping: action.shipping};

    case CLEAR_CART:
      return {...state, cart: [], shipping: "free"};

    case OPEN_ADD_TO_CART_MODAL:
      let product = action.product;
      return {
        ...state,
        showModal: true,
        cartModalData: {
          ...product,
          qty: parseInt(action.qty),
          sum: (product.discount ? product.salePrice : product.priceValue) * parseInt(action.qty)
        }
      }

    case HIDE_ADD_TO_CART_MODAL:
      return {...state, showModal: false, cartModalData: undefined}

    default:
      return state;
  }
}

const persistConfig = {
  keyPrefix: "molla-",
  key: "cartlist",
  storage
}

export default persistReducer(persistConfig, cartReducer);