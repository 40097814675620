import React, {useState, useEffect} from 'react';
import OwlCarousel from "../features/owl-carousel";
import {Link} from "react-router-dom";
import {categorySlider} from "../settings";
import {useAppState} from "../../contexts/app/app.provider";
import {connect} from "react-redux";
import {toggleCategoryFilter} from "../../redux/actions";

function PageHeader(props) {
  const appCategories = useAppState('categories');
  const {title, subTitle, displayCategory, filters} = props;
  // const [selected, setSelected] = useState();
  const [categories, setCategories] = useState([{"key": "All", "value": "All", "icon": "all_icon.png"}]);

  let icons = ["all_icon.png", "decor_icon.png", "garden_icon.png", "accent_furniture_icon.png", "table_top_icon.png"];

  useEffect(() => {
    let cats = [{key: "All", value: "All", icon: "all_icon.png"}];
    appCategories.forEach((cat, index) => {
      cats.push({...cat, key: cat.slug, value: cat.title, icon: cat.icon || "all_icon.png"})
    })
    setCategories(cats);
  }, []);

  if (displayCategory) {
    return (
      <OwlCarousel
        adClass="text-center product-gallery-carousel owl-full owl-nav-dark px-5 owl-theme owl-nav-inside product-category"
        carouselOptions={categorySlider} carouselId={"galleryProduct1"}>
        {
          categories.map((it, index) => {
            let selected = (filters.category || []).includes(it.key);

            return <Link to={`#`} key={index} onClick={(e) => {
              e.preventDefault();
              props.toggleCategoryFilter(it.key, true);
            }}>
              <div className={`${selected ? 'active' : ''}`}>
                <img
                  className={`${selected ? 'border border-dark' : ''}`}
                  style={selected ? {borderRadius: '7px'} : {}}
                  src={`${process.env.PUBLIC_URL}/assets/images/${it.icon}`}
                  alt="post_image"
                  width="50"
                  height="50"
                />
                {/*<img src={`https://dummyimage.com/4:4x400`} alt="post_image" width="300" height="300"/>*/}
                <div className={'product-title'}>
                  {it.value}
                </div>
              </div>
            </Link>
          })
        }
      </OwlCarousel>
    );
  }
  return (
    <div
      // className="page-header "
      // style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/page-header-bg.jpg)`}}
    >
      <div className="container-fluid text-center">
        <h2 className="page-title">{title}<span>{subTitle}</span></h2>
      </div>
    </div>
  );
}

// export default React.memo(PageHeader);

export const mapStateToProps = (state) => {
  return {
    filters: state.filters
  }
}

export default connect(
  mapStateToProps, {toggleCategoryFilter}
)(PageHeader);