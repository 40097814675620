import React, {useEffect} from 'react';
import {Redirect, Route, useHistory} from 'react-router-dom';
import {useAppDispatch, useAppState} from "../contexts/app/app.provider";
import {AuthContext} from '../contexts/auth/auth.context';
import {AuthProvider} from '../contexts/auth/auth.provider';

import LoadingOverlay from '../components/features/loading-overlay';
import RouteComponent from './RouteComponent';

import {scrollTop} from '../utils';

export default function AppRoot({pct}) {

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (pct) {
      dispatch({type: 'SET_PCT', payload: pct});
    }
  }, [])

  let history = useHistory();

  useEffect(() => {
    scrollTop();
    let query = new URLSearchParams(window.location.search);
    let path = query.get("path") || "";
    // path = JSON.stringify(path).replace(/\"/g,'');
    // console.log(`react path ${new Date().getTime()} = `,path);
    // alert(path)
    // path = path.replace(/\/demo7/g,'')
    if (path && path.length) {
      // console.log('in = ', path)
      history.push(path)
    }
  }, []);

  return (
    <AuthProvider>
      <React.Suspense fallback={<LoadingOverlay/>}>
        {/*<React.Suspense fallback={<LoadingOverlay/>}>*/}
        <RouteComponent/>
        {/*<Switch>*/}
        {/*  /!*<Route path={`${process.env.PUBLIC_URL}/elements`} component={ElementPages}/>*!/*/}
        {/*  /!*<Route path={`${process.env.PUBLIC_URL}/product`} component={ProductPages}/>*!/*/}
        {/*  /!*<Route path={`${process.env.PUBLIC_URL}/collections`} component={CollectionPages}/>*!/*/}
        {/*  /!*<Route path={`${process.env.PUBLIC_URL}/virtualshowrooms`} component={VirtualShowroomsPages}/>*!/*/}
        {/*  /!*<Route path={`${process.env.PUBLIC_URL}/shop`} component={ShopPages}/>*!/*/}
        {/*  /!*<Route path={`${process.env.PUBLIC_URL}/blog`} component={BlogPages}/>*!/*/}
        {/*  /!*<Route path={`${process.env.PUBLIC_URL}/pages`} component={OtherPages}/>*!/*/}

        {/*  <GuestRoute path={`${process.env.PUBLIC_URL}/collections`}>*/}
        {/*    <CollectionPages/>*/}
        {/*  </GuestRoute>*/}

        {/*  <GuestRoute path={`${process.env.PUBLIC_URL}/showrooms`}>*/}
        {/*    <VirtualShowroomPages/>*/}
        {/*  </GuestRoute>*/}

        {/*  <PrivateRoute path={`${process.env.PUBLIC_URL}/quotations`}>*/}
        {/*    <QuotationPages/>*/}
        {/*  </PrivateRoute>*/}

        {/*  <PrivateRoute path={`${process.env.PUBLIC_URL}/profile`}>*/}
        {/*    <QuotationPages/>*/}
        {/*  </PrivateRoute>*/}

        {/*  <PrivateRoute path={`${process.env.PUBLIC_URL}/wishlist`}>*/}
        {/*    <QuotationPages/>*/}
        {/*  </PrivateRoute>*/}

        {/*  <GuestRoute path={`${process.env.PUBLIC_URL}/cart`}>*/}
        {/*    <CartPages/>*/}
        {/*  </GuestRoute>*/}

        {/*  <Route path={`${process.env.PUBLIC_URL}/login`} component={LoginPage}/>*/}
        {/*  <Route path={`${process.env.PUBLIC_URL}/signup`} component={SignUpPage}/>*/}
        {/*  <Route path={`${process.env.PUBLIC_URL}/review-pending`} component={RequestReviewPendingPage}/>*/}
        {/*  <Route path={`${process.env.PUBLIC_URL}/review-rejected`} component={RequestReviewRejectedPage}/>*/}
        {/*  <Route path={`${process.env.PUBLIC_URL}/forgot-password`} component={ForgotPasswordPage}/>*/}

        {/*  /!*<GuestRoute path={`${process.env.PUBLIC_URL}/shop`}>*!/*/}
        {/*  /!*  <ShopPage/>*!/*/}
        {/*  /!*</GuestRoute>*!/*/}

        {/*  <GuestRoute path={`${process.env.PUBLIC_URL}/products`}>*/}
        {/*    <ProductsPage/>*/}
        {/*  </GuestRoute>*/}

        {/*  <Route exact={true} path={`${process.env.PUBLIC_URL}/404`}>*/}
        {/*    <Layout>*/}
        {/*      <ErrorPage/>*/}
        {/*    </Layout>*/}
        {/*  </Route>*/}

        {/*  <GuestRoute path={`${process.env.PUBLIC_URL}/`}>*/}
        {/*    <HomePage/>*/}
        {/*  </GuestRoute>*/}
        {/*</Switch>*/}
      </React.Suspense>
    </AuthProvider>
  )
}