import {findIndex} from '../utils';
import _ from "lodash";

/**
 * Get visible products
 *
 * @param {Array} products
 * @param {Object} param1
 * @return {Array} filtered products
 */
export const getVisibleProducts = (products, {sortBy, category, size, brand, color, rating, value}) => {
  return products.filter(item => {
    let catResult = false, sizeResult = false, brandResult = false, colorResult = false, valResult = false,
      ratingResult = false;

    if (category && category.length > 0) {
      for (let i = 0; i < category.length; i++) {
        if (-1 !== findIndex(item.category, cat => cat === category[i]) || (category[i] === "Sale" && item.discount > 0) || (category[i] === 'All'))
          catResult = true;
      }
    } else {
      catResult = true;
    }

    if (size && size.length > 0) {
      for (let i = 0; i < size.length; i++) {
        if (-1 !== findIndex(item.size, sz => sz === size[i]))
          sizeResult = true;
      }
    } else {
      sizeResult = true;
    }

    if (brand && brand.length > 0) {
      for (let i = 0; i < brand.length; i++) {
        if (-1 !== findIndex(item.brands, br => br === brand[i]))
          brandResult = true;
      }
    } else {
      brandResult = true;
    }

    if (color && color.length > 0) {
      for (let i = 0; i < color.length; i++) {
        if (-1 !== findIndex(item.variants, cl => cl.color === color[i]))
          colorResult = true;
      }
    } else {
      colorResult = true;
    }

    if (rating && rating.length > 0) {
      for (let i = 0; i < rating.length; i++) {
        if (item.ratings === rating[i])
          ratingResult = true;
      }
    } else {
      ratingResult = true;
    }

    let price = item.discount ? item.salePrice : item.price;

    if (value) {
      if (value.min <= price && price <= value.max) {
        valResult = true;
      }
    } else {
      valResult = true;
    }

    return catResult && sizeResult && brandResult && colorResult && valResult && ratingResult;

  }).sort((product1, product2) => {
    let price1 = product1.discount > 0 ? product1.salePrice : product1.price;
    let price2 = product2.discount > 0 ? product2.salePrice : product2.price;

    if ('popularity' === sortBy) {
      return product2.id < product1.id ? -1 : 1;
    } else if ('rating' === sortBy) {
      return product2.ratings > product1.ratings ? 1 : -1;
    } else if ('date' === sortBy) {
      return product2.id > product1.id ? -1 : 1;
    } else if ('newness' === sortBy) {
      return product2.id > product1.id ? -1 : 1;
    } else if ('price: low to high' === sortBy) {
      return price2 > price1 ? -1 : 1;
    } else if ('price: high to low' === sortBy) {
      return price2 < price1 ? -1 : 1;
    } else {
      return product2.id < product1.id ? -1 : 1;
    }
  });
}

/**
 * Get featured products
 * @param {Array} products
 * @return {Array} featuredProducts
 */
export const getFeaturedProducts = (products) => {
  return products.filter(item => true === item.featured);
}

/**
 * Get sold products
 * @param {Array} products
 * @return {Array} saleProducts
 */
export const getSaleProducts = (products) => {
  return products.filter(item => item.discount > 0);
}

/**
 * Get new products
 * @param {Array} products
 * @return {Array} newProducts
 */
export const getNewProducts = (products) => {
  return products.filter(item => item.new);
}

/**
 * Get deal products
 * @param {Array} products
 * @return {Array} dealProducts
 */
export const getDealProducts = (products, deal) => {
  return products.filter(item => item.deal === deal);
}

/**
 * Get products which has top rating
 * @param {Array} products
 * @return {Array} topRatingProducts
 */
export const getTopRatingProducts = (products) => {
  return products.filter(product => {
    return product.ratings > 2
  }).sort((product1, product2) => {
    return product2.ratings < product1.ratings ? -1 : 1;
  });
}

/**
 * Get products which has top sale
 * @param {Array} products
 * @return {Array} topSellingProducts
 */
export const getTopSellingProducts = (products) => {
  return products.filter(item => true === item.top);
}

/**
 * Get products filtered by category
 * @param {Array} products
 * @param {String} category
 * @return {Array} filteredProducts
 */
export const getProductsByCategory = (products, category) => {
  if (category === "All") return products;

  return products.filter(item => {
    let result = false;
    if (item.products && item.products.length) {
      let filteredProducts = item.products.filter(p => {
        return !!(p.storeCategories && p.storeCategories.length && p.storeCategories.find(c => c.slug === category))
      })
      result = filteredProducts.length > 0;
    }
    return result;
  })

  // if ( category === "All" ) return products;
  //
  // if ( -1 !== category.indexOf( '&' ) ) {
  //     category = category.split( ' & ' );
  // }
  //
  // return products.filter( item => {
  //     let result = false;
  //
  //     if ( Array.isArray( category ) ) {
  //         for ( let i = 0; i < category.length; i++ ) {
  //             if ( -1 !== item.category.indexOf( category[ i ] ) ) {
  //                 result = true;
  //             }
  //         }
  //     } else {
  //         if ( -1 !== item.category.indexOf( category ) )
  //             result = true;
  //     }
  //     return result;
  // } );
}

/**
 * Get number of products filtered by category
 * @param {Array} products
 * @param {String} category
 * @return {Integer} count of suitable products
 */
export const getCountByCategory = (products, category) => {
  if (category === "All") return products.length;
  if (category === "Sale") return products.filter(item => item.discount > 0).length;
  return products.filter(item => -1 !== item.category.indexOf(category)).length;
}

/**
 * get total Price of products in cart.
 * @param {Array} cartItems
 * @return {Float} totalPrice
 */
export const getCartTotal = cartItems => {
  let total = 0;
  for (let i = 0; i < cartItems.length; i++) {
    // total += parseInt(cartItems[i].qty, 10) * (cartItems[i].discount ? cartItems[i].salePrice : cartItems[i].price);
    total += parseInt(cartItems[i].qty, 10) * (cartItems[i].priceValue || 0);
  }
  return total;
}

/**
 * get number of products in cart
 * @param {Array} cartItems
 * @return {Integer} numbers of cart items in cartlist
 */
export const getCartCount = cartItems => {
  let total = 0;

  for (let i = 0; i < cartItems.length; i++) {
    total += parseInt(cartItems[i].qty, 10);
  }

  return total;
}

/**
 * Get number of products filtered by rating
 * @param {Array} products
 * @param {String} category
 * @return {Integer} number of products filtered by rating
 */
export const getCountByRating = (products, rating) => {
  return products.filter(item => item.rating === rating).length;
}

export const getProductRedirectURL = (match, productId, productMeta) => {
  if (productMeta) {
    if (productMeta.type === "collection") {
      return `/collections/${match.params.collectionId}/products/${productId}`
    } else if (productMeta.type === "showroom") {
      return `/showrooms/${match.params.showroomId}/products/${productId}`
    } else {
      return `/products/${productId}`
    }
  } else {
    if (match.path !== "/products/:productId") {
      if (match.path === "/products") {
        return `${match.path}/${productId}`;
      } else {
        return `${match.url}/products/${productId}`;
      }
    } else {
      return match.url.replace(match.params.productId, productId);
    }
  }
}

export const getProductFallbackURL = (match) => {
  if (match.path.indexOf('/collections/') !== -1) {
    return '/collections';
  } else if (match.path.indexOf('/showrooms/') !== -1) {
    return '/showrooms';
  } else {
    return '/products';
  }
}

export const getProductMeta = (match) => {
  if (match.path.indexOf('/collections/') !== -1) {
    return {
      type: "collection",
      itemId: match.params.collectionId,

    }
  } else if (match.path.indexOf('/showrooms/') !== -1) {
    return {type: "showroom", itemId: match.params.showroomId}
  } else {
    return {type: "product"}
  }
}

export const getProductRedirectURLFromMeta = (productMeta, productId) => {
  if (productMeta && productMeta.type === "collection") {
    return `/collections/${productMeta.itemId}/products/${productId}`;
  } else if (productMeta && productMeta.type === "showroom") {
    return `/showrooms/${productMeta.itemId}/products/${productId}`;
  } else {
    return `/products/${productId}`;
  }
}

export const getDimensions = (product) => {
  if (product.specification && product.specification["length"] && product.specification["width"] && product.specification["height"]) {
    return `${product.specification["length"]} L x ${product.specification["width"]} W x ${product.specification["height"]} H ${product.specification.unit || "inches"}`
  } else {
    return "-";
  }
};

export const getMaterials = (style) => {
  if (style.type === "3D") {
    if (style && style.parts && style.parts.length) {
      let materials = style.parts.map(it => it.finishName);
      return materials.length > 0 ? (_.join(_.uniq(materials), ", ")) : "-";
    } else {
      return "-";
    }
  } else if (style.materials) {
    return style.materials
  } else {
    return "-";
  }
};