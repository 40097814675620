import {cloneDeep} from 'lodash';
import {
  DONWLOAD_SCREENSHOT,
  REFETCH_STYLE_LIST,
  RESET_PARTS_BY_LIST,
  RESET_VIEWER_DATA,
  SET_3D_COMPONENT,
  SET_ANNOTATION_VISIBILITY,
  SET_ENV_MAP,
  SET_FINISH_TO_PART,
  SET_FULL_STYLE,
  SET_GENERATE_GLTF,
  SET_GENERATE_GLTF_WITH_COMPANY,
  SET_MODEL_PART_LIST,
  SET_MODEL_SCREEN_SHOT,
  SET_MODEL_URL,
  SET_PACKAGE_BOX_COLOR,
  SET_PACKAGE_BOX_DIMENSION,
  SET_PACKAGE_BOX_MATERIAL,
  SET_PACKAGE_BOX_OPACITY,
  SET_PRODUCT_DIMENSIONS,
  SET_REMOVE_SHAPE_FROM_VIEWER,
  SET_SHAPE_TO_STYLE,
  SET_SHAPE_TO_VIEWER,
  SET_STYLE_COUNT,
  SET_TOGGLE_PART_ACCORDION,
  SET_VIEWER_SETTINGS,
  TAKE_MODEL_SCREEN_SHOT,
  TEST_FINISH,
  TOGGLE_PACKAGE_BOX,
  UPDATE_COLOR_IN_PART,
  UPDATE_FINISH_IN_PART,
  UPDATE_PATTERN_AND_COLOR_IN_PART,
  UPDATE_PATTERN_IN_PART,
  SET_BRIGHTNESS_VALUE,
  SET_ZOOM_LOCK
} from '../../constants/action-types';

const INIT_STATE = {
  modelUrl: false,
  modelUrlLoading: false,
  trigger: 0,
  finishTrigger: 0,
  isTakeScreenshot: false,
  detailViewScreenShot: '',
  isTakenScreenshot: false,
  style: null,
  styleCount: 0,
  refetchStyleListCount: 1,
  styleUpdater: 0,
  productStyleList: [],
  updateTestFinish: 0,
  modelPartListUpdate: 0,
  updateColorCount: 0,
  updatePatternCount: 0,
  resetPartsCounter: 0,
  updatedParts: [],
  resetPartsList: [],
  getProductDimensions: null,
  annotationVisible: false,
  updateFinishInPartCount: 0
};

function updateObjectInArray({state, partId, finish, noResetColor, hexCode = "", pantone = "", colorType = "", shapeId = ""}) {

  try {

    state.updatedParts = state.updatedParts.map((item, index) => {
      if (item.partId === partId) {

        let newData = finish ? {
          ...item,
          finishId: finish.finishId || finish._id,
          materialId: finish.materialId || finish.parentMaterialCategory,
          finishName: finish.finishName || finish.name,
          diffuse: finish.diffuse,
          metallicRoughness: finish.metallicRoughness,
          finishThumb: finish.finishThumb,
          metalness: finish.metalness,
          normal: finish.normal,
          roughness: finish.roughness,
          transparency: finish.transparency,
          transparencyValue: finish.transparencyValue,
          ao: finish.ao,
          hexCode: noResetColor ? item.hexCode : '',
          pantone: noResetColor ? item.pantone : '',
          colorType: noResetColor ? item.colorType : '',
          patternDiffuse: noResetColor ? item.patternDiffuse : '',
          patternId: noResetColor ? item.patternId : '',
          patternName: noResetColor ? item.patternName : '',
          subFinishDiffuse: '',
          isAlphaPattern: finish.isAlphaPattern,
          alphaTest: finish.isAlphaPattern ? finish.alphaTest : 0,
          alphaDiffuse: finish.alphaDiffuse ? finish.alphaDiffuse : '',
          renderedImages: finish.renderedImages ? finish.renderedImages : [],
          updatedCount: item.updatedCount ? ++item.updatedCount : 1,
          shapeId: item.shapeId ? item.shapeId : undefined,
          glossiness: finish.glossiness,
        } : shapeId ? {
          ...item,
          shapeId: shapeId ? shapeId : item.shapeId ? item.shapeId : undefined
        } : {
          ...item,
          hexCode,
          pantone,
          colorType,
          subFinishDiffuse: '',
        };

        return cloneDeep(newData)

      }

      return cloneDeep(item);

    });

    return cloneDeep(state);

  } catch (e) {

    return cloneDeep(state);

  }

}

function updatePatternOfStyle({state, partId, pattern = {}}) {

  try {

    state.updatedParts = state.updatedParts.map((item, index) => {
      if (item.partId === partId) {

        return {
          ...item,
          patternName: pattern.name || '',
          patternDiffuse: pattern.croppedImage || '',
          patternId: pattern._id || '',
          subFinishDiffuse: pattern.thumb || '',
          updatedCount: item.updatedCount ? ++item.updatedCount : 1
        };

      }

      return item;

    });

    return state;

  } catch (e) {

    return state;

  }

}

function updatePatternAndColorOfStyle({
                                        state, partId,
                                        patternName = '',
                                        patternId = '',
                                        patternDiffuse = '',
                                        hexCode = '',
                                        pantone = '',
                                        colorType = '',
                                        subFinishDiffuse = ''
                                      }) {

  try {

    state.updatedParts = state.updatedParts.map((item, index) => {
      if (item.partId === partId) {

        return {
          ...item,
          patternName,
          patternDiffuse,
          patternId,
          hexCode,
          pantone,
          colorType,
          subFinishDiffuse,
          updatedCount: item.updatedCount ? ++item.updatedCount : 1
        };

      }

      return item;

    });

    return state;

  } catch (e) {

    return state;

  }

}

export const gltfReducer = (state = INIT_STATE, action) => {

  let obj;

  switch (action.type) {
    case RESET_VIEWER_DATA:
      return {
        ...state,
        ...INIT_STATE,
      };

    case SET_MODEL_URL:
      return {
        ...state,
        modelUrl: action.payload.modelUrl,
        modelUrlLoading: !!action.payload.modelUrl,
        forceModelLoad: action.payload.forceModelLoad
      };

    case SET_FULL_STYLE:

      obj = {
        ...state,
        style: {...action.payload.style, ...(action.payload.style.email && {email: action.payload.style.email})},
        updatedParts: cloneDeep(action.payload.style.parts || []),
        styleUpdater: ++state.styleUpdater
      };
      action.payload.finishTrigger ? obj.finishTrigger = ++state.finishTrigger : null;
      return obj;

    case UPDATE_FINISH_IN_PART: {
      let {partId, finish, noResetColor, updateFinishInPartCount} = action.payload;
      updateFinishInPartCount = updateFinishInPartCount || ++state.updateFinishInPartCount;
      return {
        ...updateObjectInArray({
          state: Object.assign({}, {
            ...state,
            updatedParts: cloneDeep(state.updatedParts || [])
          }),
          partId,
          finish,
          noResetColor
        }), updateFinishInPartCount
      };
    }
    case UPDATE_COLOR_IN_PART: {
      let {partId, hexCode, pantone, colorType} = action.payload;
      return {
        ...updateObjectInArray({
          state: Object.assign({}, {
            ...state,
            updatedParts: cloneDeep(state.updatedParts || [])
          }),
          partId,
          hexCode,
          pantone,
          colorType
        }),
        updateColorCount: ++state.updateColorCount
      };
    }
    case UPDATE_PATTERN_IN_PART: {
      let {partId, pattern} = action.payload;
      return {
        ...updatePatternOfStyle({
          state: Object.assign({}, {
            ...state,
            updatedParts: cloneDeep(state.updatedParts || [])
          }),
          partId,
          pattern
        }),
        updatePatternCount: ++state.updatePatternCount
      };
    }
    case UPDATE_PATTERN_AND_COLOR_IN_PART: {
      let {
        partId,
        patternName,
        patternId,
        patternDiffuse,
        hexCode,
        pantone,
        colorType,
        subFinishDiffuse
      } = action.payload;

      partId = partId || "";
      patternName = patternName || "";
      patternId = patternId || "";
      patternDiffuse = patternDiffuse || "";
      hexCode = hexCode || "";
      pantone = pantone || "";
      colorType = colorType || "";
      subFinishDiffuse = subFinishDiffuse || "";

      return {
        ...updatePatternAndColorOfStyle({
          state: Object.assign({}, {
            ...state,
            updatedParts: cloneDeep(state.updatedParts || [])
          }),
          partId,
          patternName,
          patternId,
          patternDiffuse,
          hexCode,
          pantone,
          colorType,
          subFinishDiffuse
        }),
        updatePatternCount: ++state.updatePatternCount
      };
    }
    case SET_FINISH_TO_PART:
      return {
        ...state,
        partsToApplyFinish: action.payload.partsToApplyFinish,
        finishTrigger: ++state.finishTrigger,
        style: cloneDeep(action.payload.style || []),
      };

    case SET_MODEL_PART_LIST:
      return Object.assign({}, {
        ...state
      }, {
        modelPartList: action.payload.modelPartList && action.payload.modelPartList.map(i => ({...i})) || null,
        modelPartListUpdate: ++state.modelPartListUpdate
      });

    case SET_MODEL_SCREEN_SHOT:

      obj = {
        ...state,
        style: {...state.style},
        isTakeScreenshot: action.payload.isTakeScreenshot,
        isTakenScreenshot: action.payload.isTakenScreenshot,
      };

      !obj.style ? obj.style = {} : null;
      action.payload.detailViewScreenShot ? obj.detailViewScreenShot = String(action.payload.detailViewScreenShot) : null;
      action.payload.detailView ? obj.style = {...obj.style, detailView: action.payload.detailView} : null;
      action.payload.thumb ? obj.style = {...obj.style, thumb: action.payload.thumb} : null;
      action.payload.mobileIcon ? obj.style = {...obj.style, mobileIcon: action.payload.mobileIcon} : null;
      action.payload.saveScreenShot != null || action.payload.saveScreenShot !== undefined ? obj.saveScreenShot = action.payload.saveScreenShot : null;
      action.payload.isCreateStyleInProcess != null || action.payload.isCreateStyleInProcess !== undefined ? obj.isCreateStyleInProcess = action.payload.isCreateStyleInProcess : null;
      action.payload.createStyleError != null || action.payload.createStyleError !== undefined ? obj.createStyleError = action.payload.createStyleError : null;
      action.payload.isSaveScreenShotInProgress != null || action.payload.isSaveScreenShotInProgress !== undefined ? obj.isSaveScreenShotInProgress = action.payload.isSaveScreenShotInProgress : null;
      action.payload.isSaveOnlyScreenShot != null || action.payload.isSaveOnlyScreenShot !== undefined ? obj.isSaveOnlyScreenShot = action.payload.isSaveOnlyScreenShot : null;
      action.payload.styledGltfUrl ? obj.style = {...obj.style, styledGltfUrl: action.payload.styledGltfUrl} : null;

      return obj;

    case TAKE_MODEL_SCREEN_SHOT:
      return {
        ...state,
        isTakeScreenshot: action.payload.isTakeScreenshot,
        isTakenScreenshot: action.payload.isTakenScreenshot,
        isCreateStyleInProcess: action.payload.isCreateStyleInProcess,
        isSaveScreenShotInProgress: action.payload.isSaveScreenShotInProgress,
        isSaveOnlyScreenShot: action.payload.isSaveOnlyScreenShot,
        finishThumbClick: action.payload.finishThumbClick
      };

    case SET_STYLE_COUNT:
      return {
        ...state,
        styleCount: action.payload.styleCount,
      };

    case REFETCH_STYLE_LIST:
      return {
        ...state,
        refetchStyleListCount: ++state.refetchStyleListCount
      };

    case TEST_FINISH:
      obj = {
        ...state,
        updateTestFinish: ++state.updateTestFinish,
      };

      action.payload.name ? obj.testName = action.payload.name : null;
      obj.testDiffuse = action.payload.diffuse ? action.payload.diffuse : '';
      obj.testMetalness = action.payload.metalness ? action.payload.metalness : '';
      obj.testAO = action.payload.ao ? action.payload.ao : '';
      obj.testNormal = action.payload.normal ? action.payload.normal : '';
      obj.testRoughness = action.payload.roughness ? action.payload.roughness : '';
      obj.testTransparency = action.payload.transparency ? action.payload.transparency : '';
      obj.testMetallicRoughness = action.payload.metallicRoughness ? action.payload.metallicRoughness : '';
      obj.testColor = action.payload.hexCode ? action.payload.hexCode : '';
      obj.isAlphaPattern = action.payload.isAlphaPattern ? action.payload.isAlphaPattern : false;
      obj.alphaTest = action.payload.isAlphaPattern ? action.payload.alphaTest || 0.5 : 0;
      return obj;

    case SET_TOGGLE_PART_ACCORDION:

      return {
        ...state,
        toggleAccordion: action.payload.toggleAccordion,
      };

    case SET_3D_COMPONENT:

      return {
        ...state,
        handleClick3DComponents: action.payload.handleClick3DComponents,
      };

    case RESET_PARTS_BY_LIST:

      return {
        ...state,
        resetPartsCounter: ++state.resetPartsCounter,
        resetPartsList: action.payload.partsList,
      };

    case SET_GENERATE_GLTF:

      return {
        ...state,
        generateGLTF: action.payload
      };

    case SET_GENERATE_GLTF_WITH_COMPANY:

      return {
        ...state,
        generateGLTFWithCompany: action.payload
      };

    case SET_PRODUCT_DIMENSIONS:

      return {
        ...state,
        getProductDimensions: action.payload
      };


    case TOGGLE_PACKAGE_BOX:

      return {
        ...state,
        TogglePackageboxVisibility: action.payload
      };

    case SET_PACKAGE_BOX_DIMENSION:

      return {
        ...state,
        UpdatePackageboxDimension: action.payload
      };

    case SET_PACKAGE_BOX_OPACITY:

      return {
        ...state,
        ChangePackageboxOpacity: action.payload
      };

    case SET_PACKAGE_BOX_MATERIAL:

      return {
        ...state,
        ApplyPackageboxMaterial: action.payload
      };

    case SET_PACKAGE_BOX_COLOR:

      return {
        ...state,
        ChangePackageboxColor: action.payload
      };

    case SET_VIEWER_SETTINGS:

      return {
        ...state,
        viewerSettings: {...(state.viewerSettings || {}), ...(action.payload || {})}
      };


    case DONWLOAD_SCREENSHOT:

      return {
        ...state,
        downloadScreenshot: action.payload
      };

    case SET_ANNOTATION_VISIBILITY:

      return {
        ...state,
        annotationVisible: action.payload
      };

    case SET_SHAPE_TO_STYLE:

      let {partId, shapeId} = action.payload;

      return {
        ...updateObjectInArray({
          state: Object.assign({}, {
            ...state,
            updatedParts: cloneDeep(state.updatedParts || [])
          }),
          partId,
          shapeId
        }),
        updateShapePartCount: ++state.updateShapePartCount
      };

    case SET_REMOVE_SHAPE_FROM_VIEWER:

      return {
        ...state,
        removeShapesFromViewer: action.payload
      };

    case SET_SHAPE_TO_VIEWER:

      return {
        ...state,
        addShapeToViewer: action.payload
      };

    case SET_ENV_MAP:

      return {
        ...state,
        style: {
          ...state.style,
          envMap: action.payload
        }
      };

    case SET_BRIGHTNESS_VALUE:

      return {
        ...state,
        style: {
          ...state.style,
          brightnessValue: action.payload
        }
      };

    case SET_ZOOM_LOCK:

      return {
        ...state,
        style: {
          ...state.style,
          zoomLock: action.payload
        }
      };

    default:
      return {...state};
  }
}
