import React from "react";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import Layout from "../components/app";
import ErrorPage from "./404/404";
import {useAppState} from "../contexts/app/app.provider";
import {AuthContext} from "../contexts/auth/auth.context";

const HomePage = React.lazy(() => import( './home-route.js' ));
const ProductsPage = React.lazy(() => import( './products/product-route' ));
const LoginPage = React.lazy(() => import( './login/login-route' ));
const SignUpPage = React.lazy(() => import( './sign-up/sign-up-route' ));
const RequestReviewPendingPage = React.lazy(() => import( './request-review-pending/request-review-pending-route' ));
const RequestReviewRejectedPage = React.lazy(() => import( './request-review-rejected/request-review-rejected-route' ));
const CollectionPages = React.lazy(() => import( './collection/collections-route' ));
const VirtualShowroomPages = React.lazy(() => import( './virtualShowroom/virtual-showroom-route' ));
const QuotationPages = React.lazy(() => import( './quotation/quote-route' ));
const CartPages = React.lazy(() => import( './cart/cart-route' ));
const ForgotPasswordPage = React.lazy(() => import( './forgot-password/forgot-password-route' ));
const AboutPage = React.lazy(() => import( './about/about-route' ));
const WishListPages = React.lazy(() => import( './wishlist/wishlist-route' ));

function GuestRoute({children, ...rest}) {

  // const appAuthenticated = useAppState('appAuthenticated');
  // if (!appAuthenticated) {
  //   return (
  //     <Route
  //       {...rest}
  //       render={({location}) =>
  //         (
  //           <Redirect
  //             to={{
  //               pathname: '/404',
  //               state: {from: location},
  //             }}
  //           />
  //         )
  //       }
  //     />
  //   )
  // }
  const pct = useAppState('pct');
  const isAuthNeeded = pct && pct.customerLoginPreferences && pct.customerLoginPreferences.loginRequired;
  const {
    authState: {isAuthenticated},
    authDispatch,
  } = React.useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({location}) =>
        !isAuthNeeded || isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {from: location},
            }}
          />
        )
      }
    />
  );
}

function PrivateRoute({children, ...rest}) {
  const {
    authState: {isAuthenticated},
    authDispatch,
  } = React.useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={({location}) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {from: location},
            }}
          />
        )
      }
    />
  );
}

class RouteComponent extends React.Component {

  static contextType = AuthContext;

  constructor(props) {
    super(props);
  }

  render() {
    const {authState: {isAuthenticated}} = this.context;

    const {location, match} = this.props;
    const query = new URLSearchParams(location.search);
    const redirect = query.get("redirect") || "";

    // if (!isAuthenticated && !['/login', '/signup', '/signUpGuest', '/forgot-password', '/verify/verify'].includes(location.pathname)) {
    //   let history = this.props.history;
    //   if (history && history.location && history.location.state && history.location.state.from && history.location.state.from.pathname) {
    //     this.props.history.push(`/login?redirect=${history.location.state.from.pathname}${history.location.state.from.search}`);
    //   }
    // }

    // console.log("isAuthenticated = ", isAuthenticated);
    // console.log("location.pathname = ", location.pathname);
    // console.log("location = ", location.search);
    // console.log("redirect = ", redirect);

    if (!isAuthenticated && ['/signup', '/login', '/forgot-password', '/login/verify'].includes(location.pathname)) {
      // console.log("A1");
      let history = this.props.history;
      if (history && history.location && history.location.state && history.location.state.from && history.location.state.from.pathname && history.location.state.from.pathname !== "/") {
        // console.log("A1.1");
        this.props.history.push(`${location.pathname}?redirect=${history.location.state.from.pathname}${history.location.state.from.search}`);
      }
    }

    if (isAuthenticated && ['/login', '/signup', '/forgot-password', '/login/verify'].includes(location.pathname)) {
      // console.log("D1");
      if (redirect !== "") {
        // console.log("D1.1");
        this.props.history.push(redirect);
      } else {
        // console.log("D1.2");
        this.props.history.push(`/`);
      }
      return "";
    }

    return <Switch>
      {/*<Route path={`${process.env.PUBLIC_URL}/elements`} component={ElementPages}/>*/}
      {/*<Route path={`${process.env.PUBLIC_URL}/product`} component={ProductPages}/>*/}
      {/*<Route path={`${process.env.PUBLIC_URL}/collections`} component={CollectionPages}/>*/}
      {/*<Route path={`${process.env.PUBLIC_URL}/virtualshowrooms`} component={VirtualShowroomsPages}/>*/}
      {/*<Route path={`${process.env.PUBLIC_URL}/shop`} component={ShopPages}/>*/}
      {/*<Route path={`${process.env.PUBLIC_URL}/blog`} component={BlogPages}/>*/}
      {/*<Route path={`${process.env.PUBLIC_URL}/pages`} component={OtherPages}/>*/}

      <GuestRoute path={`${process.env.PUBLIC_URL}/collections`}>
        <CollectionPages/>
      </GuestRoute>

      <GuestRoute path={`${process.env.PUBLIC_URL}/showrooms`}>
        <VirtualShowroomPages/>
      </GuestRoute>

      <PrivateRoute path={`${process.env.PUBLIC_URL}/quotations`}>
        <QuotationPages/>
      </PrivateRoute>

      <PrivateRoute path={`${process.env.PUBLIC_URL}/profile`}>
        <QuotationPages/>
      </PrivateRoute>

      <GuestRoute path={`${process.env.PUBLIC_URL}/wishlist`}>
        <WishListPages/>
      </GuestRoute>

      <GuestRoute path={`${process.env.PUBLIC_URL}/cart`}>
        <CartPages/>
      </GuestRoute>

      <Route path={`${process.env.PUBLIC_URL}/login`} component={LoginPage}/>
      <Route path={`${process.env.PUBLIC_URL}/signup`} component={SignUpPage}/>
      <Route path={`${process.env.PUBLIC_URL}/review-pending`} component={RequestReviewPendingPage}/>
      <Route path={`${process.env.PUBLIC_URL}/review-rejected`} component={RequestReviewRejectedPage}/>
      <Route path={`${process.env.PUBLIC_URL}/forgot-password`} component={ForgotPasswordPage}/>
      <Route path={`${process.env.PUBLIC_URL}/about`} component={AboutPage}/>
      {/*<AboutRoute/>*/}

      {/*<GuestRoute path={`${process.env.PUBLIC_URL}/shop`}>*/}
      {/*  <ShopPage/>*/}
      {/*</GuestRoute>*/}

      <GuestRoute path={`${process.env.PUBLIC_URL}/products`}>
        <ProductsPage/>
      </GuestRoute>

      <Route exact={true} path={`${process.env.PUBLIC_URL}/404`}>
        <Layout>
          <ErrorPage/>
        </Layout>
      </Route>

      <GuestRoute exact={true} path={`${process.env.PUBLIC_URL}/`}>
        <HomePage/>
      </GuestRoute>
      {/*<Route>*/}
      {/*  <Layout>*/}
      {/*    <ErrorPage/>*/}
      {/*  </Layout>*/}
      {/*</Route>*/}
      <Redirect to={'/404'}/>
    </Switch>
  }

}

export default withRouter(RouteComponent);