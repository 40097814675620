export const IS_MOBILE_DEVICE = (/iPad|Macintosh|iPhone|iPod/.test(navigator.userAgent) && 'ontouchend' in document) || /Mobile/.test(navigator.userAgent);
export const IS_TAB = !!(window.matchMedia('(min-width: 768px)').matches && window.matchMedia('(min-height: 768px)').matches);
export const DEFAULT_COLLECTION_BANNER = "assets/image/collection-dummy-banner.png";
export const APPLE_DEVICE = /iPad|iPhone|Macintosh|iPod/.test(navigator.userAgent) && 'ontouchend' in document;
export const ANDROID_DEVICE = /android/i.test(navigator.userAgent);
export const IS_IPAD = !!(APPLE_DEVICE && window.matchMedia('(min-width: 768px)').matches && window.matchMedia('(min-height: 768px)').matches);
export const IS_SAFARI = (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent));

export const TEST_DIFFUSE = window.location.origin + '/assets/img/diffuse.jpg';
export const TEST_NORMAL = window.location.origin + '/assets/img/normal.jpg';
export const TEST_METALNESS = window.location.origin + '/assets/img/metalness.jpg';
export const TEST_ROUGHNESS = window.location.origin + '/assets/img/roughness.jpg';
export const TEST_POLY9_DIFFUSE = window.location.origin + '/assets/img/poly9.jpg';
