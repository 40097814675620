import jwt from "jsonwebtoken";
import {CookieHandler} from "../../utils/CookieHelper";

const isBrowser = typeof window !== 'undefined';
export const initialState = {
  searchTerm: '',
  isSticky: false,
  isSidebarSticky: true,
  isDrawerOpen: false,
  isModalOpen: false,
  appAuthenticated: isBrowser && !!localStorage.getItem('pct') && !!jwt.decode(localStorage.getItem("pct")),
  pct: isBrowser && !!localStorage.getItem('pct') ? jwt.decode(localStorage.getItem("pct")) : undefined,
  companyName: isBrowser && !!localStorage.getItem('pct') && jwt.decode(localStorage.getItem("pct")) ? jwt.decode(localStorage.getItem("pct")).name : "",
  currencySymbol: isBrowser && !!localStorage.getItem('pct') && jwt.decode(localStorage.getItem("pct")) && jwt.decode(localStorage.getItem("pct")).currency && jwt.decode(localStorage.getItem("pct")).currency.symbol || "",
  categories: isBrowser && !!localStorage.getItem('pct') && jwt.decode(localStorage.getItem("pct")) && jwt.decode(localStorage.getItem("pct")).categories || [],
  materialCategories: isBrowser && !!localStorage.getItem('pct') && jwt.decode(localStorage.getItem("pct")) && jwt.decode(localStorage.getItem("pct")).materialCategories || [],
  banners: isBrowser && !!localStorage.getItem('pct') && jwt.decode(localStorage.getItem("pct")) && jwt.decode(localStorage.getItem("pct")).banners || [],
};

export function appReducer(state, action) {
  switch (action.type) {
    case 'SET_SEARCH_TERM':
      return {
        ...state,
        searchTerm: action.payload,
      };
    case 'SET_STICKY':
      return {
        ...state,
        isSticky: true,
      };
    case 'REMOVE_STICKY':
      return {
        ...state,
        isSticky: false,
      };
    case 'SET_SIDEBAR_STICKY':
      return {
        ...state,
        isSidebarSticky: true,
      };
    case 'REMOVE_SIDEBAR_STICKY':
      return {
        ...state,
        isSidebarSticky: false,
      };
    case 'TOGGLE_DRAWER':
      return {
        ...state,
        isDrawerOpen: !state.isDrawerOpen,
      };
    case 'TOGGLE_MODAL':
      return {
        ...state,
        isModalOpen: !state.isModalOpen,
      };
    case 'SET_PCT':
      localStorage.setItem('pct', action.payload);
      let decoded = isBrowser && !!action.payload ? jwt.decode(action.payload) : undefined;
      let guestLogin = CookieHandler.getCookieValue('guestLogin');

      if (!guestLogin && !!(decoded.customerLoginPreferences && decoded.customerLoginPreferences.loginRequired)) {
        CookieHandler.setCookie('guestLogin', true, 365);
      }

      return {
        ...state,
        appAuthenticated: !!decoded,
        pct: decoded,
        companyName: decoded && decoded.name || "",
        currencySymbol: decoded.currency && decoded.currency.symbol || "",
        categories: decoded.categories || [],
        materialCategories: decoded.materialCategories || [],
        banners: decoded.banners || [],
      };
    default: {
      throw new Error(`Unsupported action type at App Reducer`);
    }
  }
}
