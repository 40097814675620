import React, {Fragment, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {connect} from 'react-redux';

// Common Header Components
import MainMenu from './partials/main-menu';
import CartMenu from './partials/cart-menu';
import LoginModal from '../features/modal/login-modal';
import {AuthContext} from '../../contexts/auth/auth.context';

import {showModal} from '../../redux/actions';
import {useAppState} from "../../contexts/app/app.provider";

function Header(props) {

  const companyName = useAppState('companyName');
  const pct = useAppState('pct');
  let showAbout = false;

  if (pct && pct.storeFrontSettings && pct.storeFrontSettings.subDomain && ["testing", "anuraj"].includes(pct.storeFrontSettings.subDomain)) {
    showAbout = true;
  }

  let history = useHistory();
  const [path, setPath] = useState("");
  const {
    authState: {isAuthenticated, user},
    authDispatch,
  } = React.useContext(AuthContext);

  useEffect(() => {
    setPath(window.location.pathname);
  });

  function onLogout(e) {
    e && e.preventDefault();
    localStorage.removeItem('token');
    authDispatch({type: 'SIGN_OUT'});
    history.push('/');
  }

  function openLoginModal(e) {
    props.showModal('login');
    e.preventDefault();
  }

  const isUserLoggedIn = !!isAuthenticated && !!user;
  const {wishlist, logo = "assets/images/logo.png", container} = props;

  return (
    <header className="header header-7">
      <div className="header-top">
        <div className={container}>
          <div className="header-left">
            {/*<div className="header-dropdown">*/}
            {/*    <Link to="#">Usd</Link>*/}
            {/*    <div className="header-menu">*/}
            {/*        <ul>*/}
            {/*            <li><Link to="#">Eur</Link></li>*/}
            {/*            <li><Link to="#">Usd</Link></li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="header-dropdown">*/}
            {/*    <Link to="#">Eng</Link>*/}
            {/*    <div className="header-menu">*/}
            {/*        <ul>*/}
            {/*            <li><Link to="#">English</Link></li>*/}
            {/*            <li><Link to="#">French</Link></li>*/}
            {/*            <li><Link to="#">Spanish</Link></li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*</div>*/}
          </div>
          <div className="header-right">
            <ul className="top-menu header-dropdown">
              <li>
                <Link to="#">Links</Link>
                <ul>
                  {
                    pct && pct.phone ? <li>
                      <a href={`tel:${pct.phone}`}>
                        <i className="icon-phone"></i>Call: {pct.phone}
                      </a>
                    </li> : null
                  }
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/wishlist`}>
                      <i className="icon-heart-o"></i>My Wishlist <span>({wishlist.length})</span>
                    </Link>
                  </li>
                  {showAbout && <li><Link to={`${process.env.PUBLIC_URL}/about`}>About Us</Link></li>}
                  {/*<li><Link to={ `${process.env.PUBLIC_URL}/pages/contact` }>Contact Us</Link></li>*/}
                  {
                    !isUserLoggedIn ? <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/login`}
                        data-toggle="modal"
                        // onClick={openLoginModal}
                      >
                        <i className="icon-user"></i>Login
                      </Link>
                    </li> : <Fragment>
                      <li>
                        <Link to={`/profile`}>
                          <i className="icon-user"></i> {`${user.firstName} ${user.lastName}`}
                        </Link>
                      </li>
                      <li>
                        <Link to={`#logout`} onClick={onLogout}>
                          Logout
                        </Link>
                      </li>
                    </Fragment>
                  }
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="header-middle sticky-header">
        <div className={container}>
          <div className="header-left">
            <button className="mobile-menu-toggler">
              <span className="sr-only">Toggle mobile menu</span>
              <i className="icon-bars"></i>
            </button>

            <Link to={`${process.env.PUBLIC_URL}/`} className="logo">
              {
                pct && pct.logo ? <img
                  // src={"https://app.poly9.ai/public/5d9dd1be2c482a1dbcaabf22/logo/3f19a9a9-f3af-4776-878b-4cd00c65c14b.jpeg"}
                  src={`${process.env.CLOUD_FRONT_URL}${pct.logo}`}
                  alt={companyName}
                  style={{maxHeight: '50px'}}
                /> : null
              }
              {/*<img src={ process.env.PUBLIC_URL + '/' + logo } alt={companyName} width="105" height="25" />*/}
            </Link>

            <MainMenu/>
          </div>
          <div className="header-right">
            {/*{*/}
            {/*  path !== '/' ? <div className="header-search header-search-extended header-search-visible">*/}
            {/*    <Link to="#" className="search-toggle" role="button"><i className="icon-search"></i></Link>*/}
            {/*    /!*<form>*!/*/}
            {/*      <div className="header-search-wrapper search-wrapper-wide">*/}
            {/*        <label htmlFor="q" className="sr-only">Search</label>*/}
            {/*        <input type="search" className="form-control" name="q" id="q" placeholder="Search product ..."*/}
            {/*               required/>*/}
            {/*        <button className="btn btn-primary" type="submit"><i className="icon-search"></i></button>*/}
            {/*      </div>*/}
            {/*    /!*</form>*!/*/}
            {/*  </div> : ''*/}
            {/*}*/}

            <CartMenu/>
          </div>
        </div>
      </div>
      <LoginModal/>

    </header>


  );
}

function mapStateToProps(state) {
  return {
    wishlist: state.wishlist.list
  }
}

export default connect(mapStateToProps, {showModal})(Header);