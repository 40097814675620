import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {ToastContainer} from 'react-toastify';
import {withRouter} from 'react-router-dom';
import store from '../redux/store';
// import Custom Components
import Header from './common/header';
import Footer from './common/footer';
import MobileMenu from './common/mobile-menu';
// import Utils
import {mobileMenu, preventProductDefault, removePreventProductDefault, stickyHeaderHandler} from '../utils';
// import Actions
import {closeQuickViewModal} from '../redux/actions';
import AddToCartModal from './AddToCartModal'

// import data

function App(props) {
  let matchedCount = 0;
  let overlayFlag = true;
  const [container, setContainer] = useState("container");
  const [prevPath, setPrevPath] = useState('');

  let mainContainer = useRef();
  let footerEle = useRef();

  useLayoutEffect(() => {
    overlayFlag = true;
    // set overlay
    // for ( let i = 0; i < innerOverlayPaths.length; i++ ) {
    //     if ( prevPath.indexOf( innerOverlayPaths[ i ] ) > 0 && props.location.pathname.indexOf( innerOverlayPaths[ i ] ) > 0 ) {
    //         overlayFlag = false;
    //     }
    //
    //     if ( prevPath === props.location.pathname ) {
    //         overlayFlag = false;
    //     }
    // }

    if (overlayFlag && !(props.location.state && props.location.state.hideOverlay)) {
      document.querySelector('body').classList.remove("loaded");
      document.querySelector("#root").classList.remove("loaded");
    }
  })

  useEffect(() => {
    mobileMenu();

    // set sticky header
    stickyHeaderHandler();
    window.addEventListener('scroll', stickyHeaderHandler, true);

    // prevent product thumb icons
    preventProductDefault();

    return () => {
      window.removeEventListener('scroll', stickyHeaderHandler);

      // remove listeners of prevent product
      removePreventProductDefault();
    }
  }, [])

  useEffect(() => {
    // set prevPathname
    setPrevPath(props.location.pathname);

    // remove overlay
    setTimeout(() => {
      document.querySelector('body').classList.add("loaded");
      document.querySelector("#root").classList.add("loaded");
    }, 200);

    // show 404 page
    // while (matchedCount < props.children.length && !matchPath(window.location.pathname, {
    //   path: props.children[matchedCount].props.path,
    //   exact: true
    // })) {
    //   matchedCount++;
    // }
    //
    // if (props.children && !props.children.length && matchPath(window.location.pathname, {
    //   path: props.children.props.path,
    //   exact: true
    // })) {
    //   matchedCount = 1;
    // }
    //
    // if ((matchedCount >= props.children.length || (props.children && !props.children.length && matchedCount === 0)) && props.location.pathname !== '/404') {
    //   console.log("redirecting to 404")
    //   window.location = process.env.PUBLIC_URL + "/404";
    // }

    // close exiting quickview modal
    if (store.getState() && store.getState().data.quickView) {
      store.dispatch(closeQuickViewModal());
    }

    // set fullwidth layout container for following routes
    let fullwidthContainerLayouts = props.location.pathname.indexOf('collections') !== -1
      || props.location.pathname.indexOf('showrooms') !== -1;

    // set the container type according to page grid type
    if (props.location.pathname.indexOf("fullwidth") !== -1 || fullwidthContainerLayouts) {
      setContainer("container-fluid");
    } else {
      setContainer("container");
    }
  })

  useEffect(() => {
    try {
      if (mainContainer.current && footerEle.current) {
        let main = mainContainer.current.getBoundingClientRect();
        let top = main.top;

        let height = window.innerHeight - footerEle.current.offsetHeight - top;

        mainContainer.current.style.minHeight = height + 'px';

      }
    } catch (e) {
    }
  }, [mainContainer, footerEle]);

  return (
    <>
      <div className="page-wrapper">
        <Header container={container} urls={prevPath}/>
        <div className={'main-container'} ref={mainContainer}>
          {
            props.children
          }
        </div>

        <Footer container={container} footerEle={footerEle}/>

        <ToastContainer autoClose={3000} className="toast-container"/>

        <AddToCartModal/>
      </div>

      <MobileMenu/>
    </>
  );
}

export default withRouter(App);

