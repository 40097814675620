import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {getCartCount, getCartTotal} from '../../../services';
import {removeFromCart} from '../../../redux/actions';
import {safeContent} from '../../../utils';
import {useAppState} from "../../../contexts/app/app.provider";

function CartMenu(props) {

  const pct = useAppState('pct');
  const currencySymbol = useAppState('currencySymbol');
  const showPrice = pct && pct.productSettings && pct.productSettings.showPrice;

  const {cartlist, removeFromCart} = props;
  let total = getCartTotal(cartlist);

  return (
    <div className="dropdown cart-dropdown">
      <Link to={`${process.env.PUBLIC_URL}/cart`} className="dropdown-toggle" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" data-display="static">
        <i className="icon-shopping-cart"></i>
        <span className="cart-count">{getCartCount(cartlist)}</span>
      </Link>

      <div className={`dropdown-menu dropdown-menu-right ${cartlist.length === 0 ? 'text-center' : ''}`}>
        {
          0 === cartlist.length ?
            <p>No products in the cart.</p> :
            <>
              <div className="dropdown-cart-products">
                {cartlist.map((item, index) => (
                  <div className="product" key={index}>
                    <div className="product-cart-details">
                      <h4 className="product-title">
                        <Link to={`${process.env.PUBLIC_URL}/products/${item.productId}`}
                              dangerouslySetInnerHTML={safeContent(item.name)}></Link>
                      </h4>

                      <span className="cart-product-info">
                        <span className="cart-product-qty">{item.qty}</span>
                        {
                          showPrice && item.priceValue ? <Fragment>
                            x {currencySymbol}
                            {item.priceValue && item.priceValue.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}
                            {/*x ${!!item.discount ? item.salePrice.toLocaleString(undefined, {*/}
                            {/*  minimumFractionDigits: 2,*/}
                            {/*  maximumFractionDigits: 2*/}
                            {/*}) : item.priceValue.toLocaleString(undefined, {*/}
                            {/*  minimumFractionDigits: 2,*/}
                            {/*  maximumFractionDigits: 2*/}
                            {/*})}*/}
                          </Fragment> : ''
                        }
                      </span>
                    </div>

                    <figure className="product-image-container">
                      <Link to={`${process.env.PUBLIC_URL}/products/${item.productId}`} className="product-image">
                        <img src={`${process.env.CLOUD_FRONT_URL}${item.thumb}`}
                             data-oi={`${process.env.CLOUD_FRONT_URL}${item.thumb}`} alt={item.name}/>
                      </Link>
                    </figure>
                    <button className="btn-remove" title="Remove Product"
                            onClick={() => removeFromCart(item.productId)}>
                      <i className="icon-close"></i>
                    </button>
                  </div>
                ))}
              </div>
              {
                showPrice ? <div className="dropdown-cart-total">
                  <span>Total</span>

                  <span className="cart-total-price">{currencySymbol}{total.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}</span>
                </div> : ''
              }

              <div className="dropdown-cart-action">
                <Link to={`${process.env.PUBLIC_URL}/cart`} className="btn btn-primary btn-block">View Cart</Link>
                {/*<Link to={`${process.env.PUBLIC_URL}/cart`}*/}
                {/*      className="btn btn-outline-primary-2">*/}
                {/*  <span>Checkout</span>*/}
                {/*  <i className="icon-long-arrow-right"></i>*/}
                {/*</Link>*/}
              </div>
            </>
        }
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    cartlist: state.cartlist.cart ? state.cartlist.cart : []
  }
}

export default connect(mapStateToProps, {removeFromCart})(CartMenu);